import { Injectable, forwardRef, Inject } from "@angular/core";
import { OrganizationApiService } from "../organization/organization.api.service";
import { map, switchMap } from "rxjs/operators";
import { forkJoin, from, Observable, of, Subscription } from "rxjs";
import { IndividualApiService } from "../individual/individual.api.service";
import { environment } from "../../../environments/environment";
import _ from "lodash";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { getQueryParams } from "../utils/queryParams.utils";
import lodash from "lodash";
import { AngularFireStorage } from "@angular/fire/storage";
import UserAuth from "../auth/userAuth.class";
import { UrlService } from "./url.service";
import moment from "moment";
import { Role } from "../enums/user.enums";
import { Visits } from "src/app/core/Visits/visits";
import { MessageArea, MessagesService } from "../messages/messages.service";
import { DesignService } from "../design/design.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { LicenseService } from "../license/license.service";
import { CommonServiceService } from "../common/common-service.service";
import { PassTypesService } from "../pass-types/pass-types.service";
import { CustomError } from "../models/general.models";
import { SettingsService } from "../settings/settings.service";
import { PreferencesService } from "../preferences/preferences.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { CertificateService } from "../certificate.service";
import { VariationsService } from "../variations/variations.service";
import { MediaService } from "../media/media.service";
import { getDeviceInfo } from "../../../assets/js/deviceInfo.js";
import { TransactionsService } from "../transactions.service";
import { GetIndPhotoUsingPrefSettings } from "../pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import { LeagueDivisionsService } from "../league-divisions.service";
import { OrgManagerService } from "../orgManager/orgManager.service";
import { GetIndFields } from "../utils/inds.utils";
import { OrgProps } from "../utils/orgs.utils";
import { PassesService } from "../passes/passes.service";
import { CommonAuthDependencies } from "../interface/types";
@Injectable({
  providedIn: "root"
})
export class DigitalIDService {
  vipPassRenderer: any;
  orgID: string;
  indID: string;
  presentationUsage: boolean;
  showPhoto: boolean | string;
  orgData: any;
  indData: any;
  private basePath: string = "/admissibility-groups";
  admissibilityGroup: AngularFireList<any[]>;
  static rendererSub: Observable<any>;
  isPhotoMustBeApproved: boolean = null;
  constructor(
    private individualApi: IndividualApiService,
    private organizationApi: OrganizationApiService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    public urlService: UrlService,
    private messagesService: MessagesService,
    private designService: DesignService,
    @Inject(forwardRef(() => Visits)) public visits: Visits,
    private orgService: OrganizationsService,
    private licenseService: LicenseService,
    private commonService: CommonServiceService,
    private passTypeService: PassTypesService,
    public settingsService: SettingsService,
    private preferencesService: PreferencesService,
    private localStorageService: LocalStorageService,
    private certificateService: CertificateService,
    private variationsService: VariationsService,
    private mediaService: MediaService,
    private transactionService: TransactionsService,
    private getIndPhotoUsingPrefSettings: GetIndPhotoUsingPrefSettings,
    private leagueDivisionsService: LeagueDivisionsService,
    private orgMgrService: OrgManagerService,
    private passesService: PassesService
  ) {
    const userAuth = getQueryParams();

    if (userAuth.orgID) {
      this.orgService.getOrgData(userAuth.orgID).then(orgData => {
        console.log("orgData", orgData);
        if (orgData) {
          orgData["key"] = userAuth.orgID;
          orgData["_key"] = userAuth.orgID;
          this.orgData = orgData;
        }
      });
    }

    this.admissibilityGroup = db.list(this.basePath);
  }

  setOrgData(orgData) {
    this.orgData = orgData;
  }

  // --- generate testing email
  generateTestEmail() {
    let rendomId = Math.floor(1000000 + Math.random() * 9999999);
    return `changeMe${rendomId}@high5.id`;
  }

  imageUpload(base64: string, folder: string, filename: string) {
    return new Observable(observer => {
      if (base64) {
        const path = "photos/" + folder + "/" + filename;
        const ref = this.storage.ref(path);
        this.storage
          .ref(path)
          .putString(base64, "data_url", { contentType: "image/jpeg" })
          .then(res => {
            if (res.state == "success") {
              ref.getDownloadURL().subscribe(
                url => {
                  observer.next({ url, path });
                  observer.complete();
                },
                err => {
                  observer.next(false);
                  observer.complete();
                }
              );
            } else {
              observer.next(false);
              observer.complete();
            }
          })
          .catch(e => console.log(e, "logo upload err"));
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  // --- check if overlay layer exist or not and return observable
  checkIfOverlayExistAndReturn(): Observable<any> {
    return new Observable(observer => {
      let t = performance.now();
      try {
        this.commonService.vipPassRenderer
          .ExtraContentPresent(this.rendererObject.context.usage)
          .then(
            res => {
              if (res == true) {
                this.getSVGFromRenderer(this.rendererObject).subscribe(
                  res => {
                    observer.next(res);
                    observer.complete();
                  },
                  error => {
                    observer.next({ base64: "", msg: "no overlay" });
                    observer.complete();
                  }
                );
              } else {
                observer.next({ base64: "", msg: "no overlay" });
                observer.complete();
              }
            },
            reason => {
              observer.next({ base64: "", msg: "no overlay" });
              observer.complete();
            }
          )
          .catch(e => {
            observer.next({ base64: "", msg: "no overlay" });
            observer.complete();
          });
      } catch (e) {
        observer.next({ base64: "", msg: "no overlay" });
        observer.complete();
      }
    });
  }

  svgUrl: string;
  svgData: any;
  orientation: any;
  newSvgData: any;
  backgroundUrl: any;
  isForOverlay: boolean = false;
  rendererObject: any = {};
  rendererAction;
  rendererMode: RendererMode;
  rendererVisibility;
  rendererFormFactor;
  useFFOrientation;
  renderDigitalID(
    orgID: string,
    indID: string,
    showPhoto: boolean | string = true,
    presentationUsage: boolean = true,
    isForOverlay: boolean = false,
    action?: "Print" | "Download" | "Display" | "DisplayAndShare",
    visibility?: "Export" | "Capture" | "Presentation" | "Overlay",
    rendererFormFactor?: any,
    mode?: RendererMode,
    useFFOrientation?: "portrait" | "landscape",
    authDependencies?: CommonAuthDependencies
  ) {
    this.orgID = orgID;
    this.indID = indID;
    this.showPhoto = showPhoto;
    this.presentationUsage = presentationUsage;
    this.isForOverlay = isForOverlay;
    this.rendererAction = action;
    this.rendererMode = mode ? mode : null;
    this.rendererVisibility = visibility;
    this.rendererFormFactor = rendererFormFactor;
    this.useFFOrientation = useFFOrientation;
    this.passType = null;

    // --- reduce call for isforoverlay procedure
    if (this.isForOverlay && this.rendererObject) {
      this.rendererObject["context"].UntaggedObjectVisibility = "hide";
      this.rendererObject["context"].usage = "Overlay";
      this.rendererObject["context"].Visibility = "Overlay";
      return this.checkIfOverlayExistAndReturn();
    } else {
      // --- continue normal procedure
      return forkJoin({
        userAuth: of(getQueryParams()),
        individual: from(
          orgID && indID
            ? this.individualApi.getIndDataNew(
              orgID,
              indID,
              undefined,
              true,
              undefined,
              authDependencies?.indOperationsAuthDependencies
            )
            : null
        ),
        organization: this.orgService.getOrgData(orgID, OrgProps.DID),
        isPhotoMustBeApproved: this.getPhotoMustBeApprovedPref(orgID),
        photoUsedInID: this.getPhotoUsedInIDPref(orgID),
        orgCurrentCycle: this.getOrgCurrentCycle(orgID),
        authDependencies: of(authDependencies)
      }).pipe(
        switchMap(this.getVariationSvgData),
        switchMap(this.buildRendererObject),
        switchMap(async (rendererObject: any) => {
          // console.log('262 : ----------- rendererObject: ', lodash.cloneDeep(rendererObject));
          let indCerts = [];
          let certificatesList = [];

          // check if computeDateRange function available for bus pass
          let isComputeFunctionAvailable = this.svgData.includes(
            "~^computeDateRange("
          );
          if (isComputeFunctionAvailable) {
            let splitStr = this.svgData.split("~^computeDateRange(");
            let promises: any[] = [];
            let paramsList = [];
            splitStr.forEach((element, index) => {
              if (index > 0) {
                let str = element.split(")");
                paramsList.push(str[0]);
              }
            });

            let uniqList = lodash.uniq(paramsList);
            uniqList.forEach(ele => {
              promises.push(this.evaluateBusPassTag(ele));
            });

            let [
              promisesRes,
              promisesErr
            ] = await this.commonService.executePromise(Promise.all(promises));
            if (promisesRes) {
              let prefObj = {};
              promisesRes.forEach(element => {
                let key = Object.keys(element);
                prefObj[key[0]] = element[key[0]];
              });
              paramsList.forEach(ele => {
                this.svgData = this.svgData.replace(
                  `~^computeDateRange(${ele})~^`,
                  prefObj[ele]
                );
              });
            }
          }

                    // check if computeDateRange function available for bus pass /lunch pass/ admissible in person Pass
        if (this.svgData.includes(
          "~^evaluate("
          )) {
          let splitStr = this.svgData.split("~^evaluate(");
          let promises: any[] = [];
          let paramsList = [];
          splitStr.forEach((element, index) => {
            if (index > 0) {
              let str = element.split(")");
              paramsList.push(str[0]);
            }
          });

          let uniqList = lodash.uniq(paramsList);

          uniqList.forEach(ele => {
            promises.push(this.evaluateRangeTag(ele));
          });

          let [
            promisesRes,
            promisesErr
          ] = await this.commonService.executePromise(Promise.all(promises));
          console.log('promisesRes: ', promisesRes);
          if(!promisesErr && promisesRes) {
            let prefObj = {};
            promisesRes.forEach(element => {
              let key = Object.keys(element);
              prefObj[key[0]] = element[key[0]];
            }); 
            paramsList.forEach(ele => {
              this.svgData = this.svgData.replace(
                `~^evaluate(${ele})~^`,
                prefObj[ele]
                );
              });
            }
        }
          // check if given value inside isEmpty function returns 
          if (this.svgData.includes(
            "~^isEmpty("
          )) {
            let promises: any[] = [];
            let paramsList = [];
            let splitStr = this.svgData.split("isEmpty(");
            splitStr.forEach((ele, index) => {
              if (index > 0) {
                let str = ele.split(")");
                paramsList.push(str[0]);
              }
            });

            let uniqList = lodash.uniq(paramsList);
            uniqList.forEach(ele => {
              promises.push(this.evaluateIsEmptyTag(ele));
            });

            let [
              promisesRes,
              promisesErr
            ] = await this.commonService.executePromise(Promise.all(promises));
            if (promisesRes) {
              let prefObj = {};
              promisesRes.forEach(element => {
                let key = Object.keys(element);
                prefObj[key[0]] = element[key[0]];
              });
              paramsList.forEach(ele => {
                this.svgData = this.svgData.replace(
                  `~^isEmpty(${ele})~^`,
                  prefObj[ele]
                );
              });
            }
          }
          if (this.svgData.includes(
            "~^equals("
          )) {
            let promises: any[] = [];
            let paramsList = [];
            let splitStr = this.svgData.split("equals(");
            splitStr.forEach((ele, index) => {
              if (index > 0) {
                let str = ele.split(")");
                paramsList.push(str[0]);
              }
            });

            let uniqList = lodash.uniq(paramsList);

            uniqList.forEach(ele => {
              promises.push(this.evaluateEqualsByTag(ele));
            });

            let [
              promisesRes,
              promisesErr
            ] = await this.commonService.executePromise(Promise.all(promises));
            if (promisesRes) {
              let prefObj = {};
              promisesRes.forEach(element => {
                let key = Object.keys(element);
                prefObj[key[0]] = element[key[0]];
              });
              paramsList.forEach(ele => {
                this.svgData = this.svgData.replace(
                  `~^equals(${ele})~^`,
                  prefObj[ele]
                );
              });
            }
          }
          if (
            this.orgData &&
            this.orgData.type == "sportsteam" &&
            this.svgData.includes("~^ind.cert")
          ) {
            // --- read required data (based on survey questions)
            let readPrerequisitePromises = [];
            readPrerequisitePromises.push(
              this.certificateService
                .readCertificate(Role.ORG, this.orgID, null)
                .then(certs => (certificatesList = certs))
            );
            readPrerequisitePromises.push(
              this.certificateService
                .readCertificationApprovalData(this.orgID, indID)
                .then(certs => (indCerts = certs))
            );
            if (readPrerequisitePromises.length > 0) {
              await Promise.all(readPrerequisitePromises);
            }
          }
       
          if (this.svgData.includes(
            "~^not("
          )) {
            let promises: any[] = [];
            let paramsList = [];
            let splitStr = this.svgData.split("not(");
            splitStr.forEach((ele, index) => {
              if (index > 0) {
                let str = ele.split(")");
                paramsList.push(str[0]);
              }
            });

            let uniqList = lodash.uniq(paramsList);

            uniqList.forEach(ele => {
              promises.push(this.evaluateNotyTag(ele));
            });

            let [
              promisesRes,
              promisesErr
            ] = await this.commonService.executePromise(Promise.all(promises));
            if (promisesRes) {
              let prefObj = {};
              promisesRes.forEach(element => {
                let key = Object.keys(element);
                prefObj[key[0]] = element[key[0]];
              });
              paramsList.forEach(ele => {
                this.svgData = this.svgData.replace(
                  `~^not(${ele})~^`,
                  prefObj[ele]
                );
              });
            }
          }
          // console.log('-------- rendererObject: ', lodash.cloneDeep(rendererObject));
          let backgroundUrl = rendererObject
            ? rendererObject.data.backgroundUrl
            : "";
          let capturePhoto: any =
            typeof this.showPhoto === "string" ? showPhoto : "";

          // this.orgData["logo"] = orgLogo;
          let customCtx = {
            background: backgroundUrl,
            preventUrlShortening: true
          };
          // console.log('--------------- customCtx: ', lodash.cloneDeep(customCtx));

          let orgMgrRqrdProps = ["studioName"];
          if (lodash.get(this.orgData, "type") == "sportsteam") {
            orgMgrRqrdProps = lodash.concat(orgMgrRqrdProps, [
              "certificationStamp",
              "certificationSignature"
            ]);
          }

          let updatedSvg = await this.commonService.prepareMessage(
            this.svgData,
            {
              urlService: this.urlService,
              licenseService: this.licenseService,
              variationsService: this.variationsService,
              certificateService: this.certificateService,
              orgService: this.orgService,
              transactionsService: this.transactionService,
              getIndPhotoPipe: this.getIndPhotoUsingPrefSettings,
              leagueDivisionsService: this.leagueDivisionsService,
              orgMgrService: this.orgMgrService,
              indApiService: this.individualApi,
              settingsService: this.settingsService,
              passesService: this.passesService,
              passTypeService: this.passTypeService,
              visitsService: this.visits
            },
            {
              orgID: orgID,
              indID: indID,
              passTypeID: lodash.get(this.passType, "key"),
              passID: lodash.get(this.passData, "key"),
              designTypeID: this.designTypeId,
              variationID: this.variationKey,
              indPhotoIps: {
                mode:
                  this.rendererMode == RendererMode.CAPTURE
                    ? "capture"
                    : "display",
                isPhotoMustBeApproved: this.isPhotoMustBeApproved,
                photoPrefValue: this.photoUsedInIDPrefValue,
                photoUrl: capturePhoto
              },
              currentCycle: this.orgCurrentCycle,
              leagueID: lodash.get(this.orgData, "leagueID")
            },
            {
              // orgData: this.orgData,
              indData: this.indData,
              visitData: this.visit,
              passTypeData: this.passType,
              passData: this.passData,
              variationData: rendererObject.data.variationObj,
              indCerts: indCerts,
              certConfigs: certificatesList
            },
            {
              orgMgrProps: orgMgrRqrdProps,
              grdnProps: GetIndFields.DID_GRDN_PROPS,
              customCtx
            }
          );
          rendererObject.design = `${updatedSvg}`;
          this.rendererObject = rendererObject;
          delete this.rendererObject.data;
          return await this.getSVGFromRenderer(this.rendererObject).toPromise();
        })
      );
    }
  }

  computeTagRef: any;
  async evaluateBusPassTag(funParams: string) {
    return new Promise(async (resolve, reject) => {
      let fun = funParams;
      let paramList = fun.split(",");
      let promises = [];
      paramList.forEach((params, index) => {
        params = params.trim();
        if (index != 0) {
          // console.log('params: ', params);
          if (params.includes(".")) {
            promises.push(
              this.commonService.prepareMessage(
                `~^${params}~^`,
                {
                  urlService: this.urlService,
                  licenseService: this.licenseService
                },
                { orgID: this.orgData.key, indID: this.indData._key },
                { orgData: this.orgData, indData: this.indData },
                undefined,
                true
              )
            );
          } else {
            promises.push(params);
          }
        }
      });

      let [tagResponse, error] = await this.commonService.executePromise(
        Promise.all(promises)
        );
      let isPassValid = false;
      // console.log('tagResponse: ', tagResponse);
      if (
        tagResponse[0] == "1" ||
        tagResponse[0] == "true" ||
        tagResponse[0] == "2" ||
        tagResponse[0] == "false"
      ) {
        isPassValid =
          (tagResponse[0] == "1" || tagResponse[0] == "true") ? true : false;
      } else if (tagResponse[0] == "3" || tagResponse[0] == "7") {
        isPassValid = await this.commonService.computeDateRange(
          tagResponse[1],
          tagResponse[2],
          paramList[0]
        );
      } else if (tagResponse[0] == "4" || tagResponse[0] == "8") {
        isPassValid = !await this.commonService.computeDateRange(
          tagResponse[1],
          tagResponse[2],
          paramList[0]
        );
      } else if (tagResponse[0] == "5" || tagResponse[0] == "9") {
        isPassValid = await this.commonService.isDateTimeRangValid(
          JSON.parse(tagResponse[0]),
          paramList[0],
          tagResponse[1],
          tagResponse[2],
          tagResponse[3],
          tagResponse[4]
          );
      } else if (tagResponse[0] == "6" || tagResponse[0] == "10") {
        isPassValid = await this.commonService.isDateTimeRangValid(
          JSON.parse(tagResponse[0]),
          paramList[0],
          tagResponse[1],
          tagResponse[2],
          tagResponse[3],
          tagResponse[4]
        );
      }
      resolve({ [fun]: isPassValid });
    });
  }

  async evaluateRangeTag(funParams: string) {
    return new Promise(async (resolve, reject) => {
      let tagsList = ['ind.accessCampus',
        'ind.accessCafeteria',
        'ind.accessSocial',
        'ind.accessAthletics',
        'ind.permissionToLeave',
        'ind.accessBus',
        'ind.accessParking',
        'ind.idValid',
        'ind.permissionFlexSchedule'];
      let fun = funParams;
      let paramList: any[] = fun.split(",");
      let campareTime: any = isNaN(paramList[1])
        ? 'now'
        : Number(paramList[1]);
      if (paramList.length > 0) {
        let params = String(paramList[0].trim());
        if (lodash.includes(tagsList, params)) {
          let strTag;
          if (params == "ind.accessBus") {
            strTag = `${campareTime}, ind.accessBus_mode   
            ,ind.accessBus_startDateTime
            ,ind.accessBus_endDateTime 
            ,ind.accessBus_invalidAfterDateTime
            ,ind.accessBus_validAfterDateTime`;
          } else if (params == "ind.accessParking") {
            strTag = `${campareTime}, ind.accessParking_mode  
            ,ind.accessParking_startDateTime
            ,ind.accessParking_endDateTime 
            ,ind.accessParking_invalidAfterDateTime
            ,ind.accessParking_validAfterDateTime`;
          } else if (params == "ind.permissionToLeave") {
            strTag = `${campareTime}, ind.permissionToLeave_mode  
            ,ind.permissionToLeave_startDateTime
            ,ind.permissionToLeave_endDateTime 
            ,ind.permissionToLeave_invalidAfterDateTime
            ,ind.permissionToLeave_validAfterDateTime`;
          } else if (params == "ind.accessSocial") {
            strTag = `${campareTime}, ind.accessSocial_mode  
            ,ind.accessSocial_startDateTime
            ,ind.accessSocial_endDateTime 
            ,ind.accessSocial_invalidAfterDateTime
            ,ind.accessSocial_validAfterDateTime`;
          } else if (params == "ind.accessAthletics") {
            strTag = `${campareTime}, ind.accessAthletics_mode  
            ,ind.accessAthletics_startDateTime
            ,ind.accessAthletics_endDateTime 
            ,ind.accessAthletics_invalidAfterDateTime
            ,ind.accessAthletics_validAfterDateTime`;
          } else if (params == "ind.accessCafeteria") {
            strTag = `${campareTime}, ind.accessCafeteria_mode  
            ,ind.accessCafeteria_startDateTime
            ,ind.accessCafeteria_endDateTime 
            ,ind.accessCafeteria_invalidAfterDateTime
            ,ind.accessCafeteria_validAfterDateTime`;
          } else if (params == "ind.accessCampus") {
            strTag = `${campareTime}, ind.accessCampus_mode  
            ,ind.accessCampus_startDateTime
            ,ind.accessCampus_endDateTime 
            ,ind.accessCampus_invalidAfterDateTime
            ,ind.accessCampus_validAfterDateTime`;
          } else if (params == "ind.idValid") {
            strTag = `${campareTime}, ind.idValid_mode  
            ,ind.idValid_startDateTime
            ,ind.idValid_endDateTime 
            ,ind.idValid_invalidAfterDateTime
            ,ind.idValid_validAfterDateTime`;
          } else if (params == "ind.permissionFlexSchedule") {
            strTag = `${campareTime}, ind.permissionFlexSchedule_mode  
            ,ind.permissionFlexSchedule_startDateTime
            ,ind.permissionFlexSchedule_endDateTime 
            ,ind.permissionFlexSchedule_invalidAfterDateTime
            ,ind.permissionFlexSchedule_validAfterDateTime`;
          } else {
            resolve({ [fun]: false })
          }
          let admissibleNowTagRes = await this.evaluateBusPassTag(strTag);
          resolve({ [fun]: admissibleNowTagRes[strTag] })
        } else {
          resolve({ [fun]: false })
        }
      } else {
        resolve({ [fun]: false })
      }
    });
  }

  evaluateIsEmptyTag(funParams: String) {
    return new Promise(async (resolve, reject) => {
      let fun: any = funParams;
      let params = fun.trim();
      let promises = [];

      if (params.includes(".")) {
        promises.push(
          this.commonService.prepareMessage(
            `~^${params}~^`,
            {
              urlService: this.urlService,
              licenseService: this.licenseService
            },
            { orgID: this.orgData.key, indID: this.indData._key },
            { orgData: this.orgData, indData: this.indData },
            undefined,
            true
          )
        );
      } else {
        promises.push(params);
      }

      let [tagResponse, error] = await this.commonService.executePromise(
        Promise.all(promises)
      );
      let isEmpty =
        lodash.isEmpty(tagResponse[0].trim()) ? true : false;
      resolve({ [fun]: isEmpty });
    });
  }

  evaluateEqualsByTag(funParams: String) {
    return new Promise(async (resolve, reject) => {
      let fun: any = funParams;
      let paramList = fun.split(",");
      let promises = [];
      paramList.forEach((params, index) => {
        if (index != 1) {
          if (params.includes(".")) {
            promises.push(
              this.commonService.prepareMessage(
                `~^${params}~^`,
                {
                  urlService: this.urlService,
                  licenseService: this.licenseService
                },
                { orgID: this.orgData.key, indID: this.indData._key },
                { orgData: this.orgData, indData: this.indData },
                undefined,
                true
              )
            );
          } else {
            promises.push(params);
          }
        }
      });


      let [tagResponse, error] = await this.commonService.executePromise(
        Promise.all(promises)
      );
      let paramsValue = paramList[1].replace(/['"]/g, '')
      let isEqual =
        lodash.isEqual(tagResponse[0].toLowerCase().trim(), paramsValue.toLowerCase().trim());
      resolve({ [fun]: isEqual });
    });
  }

    evaluateNotyTag(funParams: String) {
    return new Promise(async (resolve, reject) => {
      let fun: any = funParams;
      let params = fun.trim();
      let promises = [];

      if (params.includes(".")) {
        promises.push(
          this.commonService.prepareMessage(
            `~^${params}~^`,
            {
              urlService: this.urlService,
              licenseService: this.licenseService
            },
            { orgID: this.orgData.key, indID: this.indData._key },
            { orgData: this.orgData, indData: this.indData },
            undefined,
            true
          )
        );
      } else {
        promises.push(params);
      }

      let [tagResponse, error] = await this.commonService.executePromise(
        Promise.all(promises)
      );
      let paramsValue = tagResponse[0].replace(/['"]/g, '')
      let contrasValue =
      JSON.parse(paramsValue.trim()) ? false : true;
      resolve({ [fun]: contrasValue });
    });
  }

  // --- get org current cycle
  orgCurrentCycle;
  getOrgCurrentCycle(orgID) {
    return new Observable(observer => {
      this.commonService.getOrgCurrentCycle(orgID).then(res => {
        this.orgCurrentCycle = res;
        // console.log('==========\nthis.orgCurrentCycle: ', (this.orgCurrentCycle));
        observer.next(this.orgCurrentCycle);
        observer.complete();
      });
    });
  }

  // --- fetch photo used in ID preference
  photoUsedInIDPrefValue;
  getPhotoUsedInIDPref(orgID) {
    return new Observable(observer => {
      let orgData =
        lodash.get(this.orgData, "key") == orgID ? this.orgData : null;

      let usersData = {};
      if (orgData) lodash.set(usersData, `${orgID}`, orgData);

      this.preferencesService
        .getPreferenceByInheritance(
          Role.ORG,
          orgID,
          "photoUsedInID",
          usersData,
          true,
          "value"
        )
        .then(res => {
          this.photoUsedInIDPrefValue = res;
          // console.log('======= \nphotoUsedInIDPrefValue: ', this.photoUsedInIDPrefValue);
          observer.next(this.photoUsedInIDPrefValue);
          observer.complete();
        });
    });
  }

  // --- fetch photo must be approved preference
  isPhotoMustBeApprovedOrgID;
  getPhotoMustBeApprovedPref(orgID) {
    return new Observable(observer => {
      let orgData =
        lodash.get(this.orgData, "key") == orgID ? this.orgData : null;

      let usersData = {};
      if (orgData) lodash.set(usersData, `${orgID}`, orgData);

      this.preferencesService
        .getPreferenceByInheritance(
          Role.ORG,
          orgID,
          "photoMustBeApproved",
          usersData,
          true,
          "value"
        )
        .then(res => {
          this.isPhotoMustBeApproved = res;
          // console.log('=========\nthis.isPhotoMustBeApproved: ', this.isPhotoMustBeApproved);
          observer.next(this.isPhotoMustBeApproved);
          observer.complete();
        });
    });
  }

  async getBackgroundsUrl(backID, orgID, orientation?) {
    if (!backID || backID == "" || !orgID) return null;

    const getBGUrlBasedOnOrientation = bg => {
      if (orientation && bg.orientation != orientation && bg.rotatedSource) {
        return bg.rotatedSource.url;
      }
      return bg.source.url;
    };

    // --- prepare user data
    let usersData = {};
    if (this.orgData && this.orgData.key == orgID)
      usersData[orgID] = this.orgData;

    let mediaData = await this.mediaService.getMediaByInheritance(
      Role.ORG,
      orgID,
      backID,
      usersData,
      true
    );
    if (mediaData) return getBGUrlBasedOnOrientation(mediaData);

    return null;
  }

  doesOrgHaveLicense(licenseId): boolean {
    for (let k = 0; k < this.orgLicenses.length; k++) {
      const orgLicense = this.orgLicenses[k];
      let keys = Object.keys(orgLicense);
      for (let o = 0; o < keys.length; o++) {
        const key = keys[o];
        // if (isNaN(Number(key))) {
        //   continue;
        // }
        if (orgLicense[key]["id"] == licenseId) {
          if (
            orgLicense[key]["type"] == "expiring" &&
            orgLicense[key]["expire_on"] &&
            orgLicense[key]["expire_on"] > moment().format("x")
          ) {
            return true;
          } else if (
            orgLicense[key]["type"] == "count_prepaid" &&
            orgLicense[key]["defaultCount"] &&
            orgLicense[key]["defaultCount"] > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  orgLicenses: any[] = [];

  async getDesignTypeIdInfo(designTypeId) {
    let snapshot = await this.db
      .object(`designs/DesignTypes/${designTypeId}`)
      .query.once("value");
    return snapshot.val();
  }

  getTmDefaultDesignTypeId() {
    return new Promise(resolve => {
      let s1 = this.db
        .object(`/studios/${this.orgData.studioID}/designs/defaults/designType`)
        .valueChanges()
        .subscribe(designTypeId => {
          s1.unsubscribe();
          resolve(designTypeId);
        });
    });
  }

  getSADefaultDesignTypeId() {
    return new Promise(resolve => {
      let s1 = this.db
        .object(`/designs/defaults/designType`)
        .valueChanges()
        .subscribe(designTypeId => {
          s1.unsubscribe();
          resolve(designTypeId);
        });
    });
  }

  processSADefaultDesignTypeIdCheck() {
    return new Promise(resolve => {
      const userAuth: UserAuth = getQueryParams();
      this.getSADefaultDesignTypeId().then(designTypeId => {
        if (designTypeId) {
          this.getDesignTypeIdInfo(designTypeId).then(
            async (designTypeObj: any) => {
              // --- check org license
              if (designTypeObj) {
                if (this.orgLicenses.length == 0) {
                  this.orgLicenses = await this.licenseService.getOrgLicensesArr(
                    userAuth.orgID
                  );
                }
                for (let k = 0; k < designTypeObj.licenseType.length; k++) {
                  const licenseId = designTypeObj.licenseType[k];
                  if (this.doesOrgHaveLicense(licenseId)) {
                    resolve(designTypeId);
                  }
                }
                resolve(false);
              } else {
                resolve(false);
              }
            }
          );
        } else {
          resolve(false);
        }
      });
    });
  }

  retrieveDesignTypeId() {
    const userAuth: UserAuth = getQueryParams();
    return new Promise(async (resolve, reject) => {
      if (userAuth.designTypeId) resolve(userAuth.designTypeId);

      if (!this.orgData) {
        let orgData = await this.orgService.getOrgData(userAuth.orgID);
        if (orgData) orgData["key"] = userAuth.orgID;
        this.orgData = orgData;
      }

      // --- check org default design type Id
      if (
        this.orgData &&
        this.orgData.designs &&
        this.orgData.designs.defaults &&
        this.orgData.designs.defaults.designType
      ) {
        resolve(this.orgData.designs.defaults.designType);
      } else {
        // --- fetch studio admin design type id
        this.getTmDefaultDesignTypeId().then(designTypeId => {
          if (designTypeId) {
            // --- get design type info
            this.getDesignTypeIdInfo(designTypeId).then(
              async (designTypeObj: any) => {
                if (designTypeObj) {
                  if (this.orgLicenses.length == 0) {
                    this.orgLicenses = await this.licenseService.getOrgLicensesArr(
                      userAuth.orgID
                    );
                  }
                  // --- check org license
                  for (let k = 0; k < designTypeObj.licenseType.length; k++) {
                    const licenseId = designTypeObj.licenseType[k];
                    if (this.doesOrgHaveLicense(licenseId)) {
                      resolve(designTypeId);
                    }
                  }
                  resolve(false);
                } else {
                  // -- check in super admin default design type
                  this.processSADefaultDesignTypeIdCheck().then(res =>
                    resolve(res)
                  );
                }
              }
            );
          } else {
            // --- fetch super admin design type id
            this.processSADefaultDesignTypeIdCheck().then(res => resolve(res));
          }
        });
      }
    });
  }

  getStorageDownloadURL(path: string) {
    return new Promise(resolve => {
      try {
        this.storage
          .ref(path)
          .getDownloadURL()
          .subscribe(
            url => {
              resolve(url);
            },
            err => resolve(false)
          );
      } catch (e) {
        resolve(false);
      }
    });
  }

  getVariationKeyDesignTypeId() {
    return { variationKey: this.variationKey, designTypeId: this.designTypeId };
  }

  variationKey: string;
  designTypeId: string;
  visit: any;
  passType: any;
  passData: any;
  prepareVariationData(
    userAuth,
    individual,
    organization,
    authDependencies?: CommonAuthDependencies
  ) {
    return new Observable(observer => {
      const prepareVariationData = async () => {
        // --- prepare design type ID
        let designTypeId;
        if (this.rendererAction == "Print") {
          designTypeId = userAuth.printDesignTypeID;
        } else if (
          this.rendererMode == RendererMode.PREVIEW &&
          userAuth.designTypeId_Review
        ) {
          designTypeId = userAuth.designTypeId_Review;
        } else {
          designTypeId = userAuth.designTypeId;
        }

        // --- prepare design ID, variation ID and more
        let designID;
        let variationID;
        let variationData;
        let bgID;
        let preferredOrientation;
        let svgURL;
        if (
          (this.rendererMode != RendererMode.PREVIEW &&
            this.rendererAction == "Print" &&
            userAuth.isDesignOrVariation &&
            userAuth.designOrVariationId) ||
          (this.rendererAction == "Print" &&
            userAuth.isPrintDesignOrVariation &&
            userAuth.printDesignID) ||
          (this.rendererMode == RendererMode.PREVIEW &&
            userAuth.isDesignOrVariation_Review &&
            userAuth.designOrVariationId_Review) ||
          (userAuth.isDesignOrVariation && userAuth.designOrVariationId)
        ) {
          let isDesignOrVariation;
          let designOrVariationId;
          if (this.rendererAction == "Print") {
            isDesignOrVariation = userAuth.isPrintDesignOrVariation;
            designOrVariationId = userAuth.printDesignID;
          } else if (this.rendererMode == RendererMode.PREVIEW) {
            isDesignOrVariation = userAuth.isDesignOrVariation_Review;
            designOrVariationId = userAuth.designOrVariationId_Review;
          } else {
            isDesignOrVariation = userAuth.isDesignOrVariation;
            designOrVariationId = userAuth.designOrVariationId;
          }

          // --- check for license
          let licenseCheckRes = await this.doesOrgHaveValidLicenseForDesignType(
            userAuth.orgID,
            designTypeId
          );
          if (!licenseCheckRes.res) {
            throw licenseCheckRes.error ||
            new CustomError(
              "Organization do not have a valid license to access the design type.",
              `designTypeId: ${designTypeId}`
            );
          }

          if (isDesignOrVariation == "design") designID = designOrVariationId;
          if (isDesignOrVariation == "variation") variationID = designOrVariationId;

          // --- fetch design and/or variation data
          if (variationID) {
            let variation = await this.variationsService.getVariationByInheritance(
              Role.ORG,
              userAuth.orgID,
              designTypeId,
              variationID
            );
            if (!variation || !variation.results) {
              throw new CustomError(
                "No Variation found with the given variation id.",
                `variationID: ${variationID}`
              );
            }
            variationData = variation;

            designID = variation.results.designId;
            bgID = variation.results.backgroundId;
            preferredOrientation = variation.orientation;
          }

          if (designID) {
            let design = await this.designService.getDesignByInheritance(
              designID,
              Role.ORG,
              userAuth.orgID
            );
            if (!design || !design.source) {
              throw new CustomError(
                "No Design Found with the given design id.",
                `designID: ${designID}`
              );
            }

            svgURL = design.source.url;
            if (!preferredOrientation)
              preferredOrientation = design.orientation;
          }
        } else {
          let svgData = await this.getSvgPromise(
            organization,
            this.indID,
            userAuth.orgID,
            designTypeId,
            authDependencies
          ).toPromise();

          if (!svgData || !svgData.source || svgData == "error, null") {
            throw new CustomError(
              "Design Source not found!",
              `designTypeId: ${designTypeId}`
            );
          }
          variationData = svgData;
          // console.log('1233 : variationData: ', lodash.cloneDeep(variationData));

          variationID = svgData.variationId;
          bgID = svgData.backgroundId;
          preferredOrientation = svgData.orientation;
          svgURL = svgData.source.url;
        }

        this.designTypeId = designTypeId;
        this.variationKey = variationID;

        let promises = [];

        // --- get background url
        promises.push(
          bgID
            ? this.getBackgroundsUrl(bgID, userAuth.orgID, preferredOrientation)
            : of(null).toPromise()
        );

        // --- get design SVG
        promises.push(this.commonService.fetchXML(svgURL, true));

        // --- get visit data
        promises.push(
          userAuth.visitKey
            ? this.visits.getVisiByKey(userAuth.visitKey)
            : of(null).toPromise()
        );

        // --- get pass type data
        promises.push(
          userAuth.passTypeID
            ? this.passTypeService.getPassTypes(
              Role.ORG,
              userAuth.orgID,
              undefined,
              undefined,
              userAuth.passTypeID
            )
            : of(null).toPromise()
        );

        // --- get assigned pass data
        promises.push(
          userAuth.passId
            ? this.passesService.getPass(
              userAuth.orgID,
              userAuth.indID,
              userAuth.passId,
              this.orgCurrentCycle
            )
            : of(null).toPromise()
        );

        let [promisesResults, error] = await this.commonService.executePromise(
          Promise.all(promises)
        );

        // --- error handling
        if (error) {
          throw error instanceof CustomError
            ? error
            : new CustomError(
              this.commonService.prepareErrorMessage(error),
              `Error caught while resolving promises after 'getVariationSvgData > prepareVariationData > fetchXML' log`
            );
        }

        let bgURL = lodash.nth(promisesResults, 0);
        let svgStr = lodash.nth(promisesResults, 1);
        let visitData = lodash.nth(promisesResults, 2);
        let passTypeData = lodash.nth(promisesResults, 3);
        let assignedPassData = lodash.nth(promisesResults, 4);

        if (!svgStr)
          throw new CustomError(`Design URL is invalid. url: ${svgURL}`);

        this.svgData = svgStr;
        this.orientation = preferredOrientation;
        this.visit = visitData;
        this.passType = passTypeData;
        this.passData = assignedPassData;

        let variationSvgDataObj = {
          svgUrl: svgURL,
          backgroundUrl: bgURL || null,
          variationObj: variationData || null
        };

        return variationSvgDataObj;
      };

      prepareVariationData()
        .then(variationSvgDataObj => {
          observer.next({ individual, organization, variationSvgDataObj });
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  orgLogoUrl: any;
  private getVariationSvgData = ({
    userAuth,
    individual,
    organization,
    authDependencies
  }) => {
    this.indData = individual;
    return new Observable(observer => {
      if (!userAuth.designTypeId)
        userAuth.designTypeId = this.localStorageService.getItem(
          "designTypeId"
        );

      // --- get org logo URL
      if (
        this.orgData &&
        this.orgData.settings &&
        this.orgData.settings.visuals &&
        this.orgData.settings.visuals.logo
      ) {
        if (
          this.orgData.settings.visuals.logo.indexOf("http") > -1 ||
          this.orgData.settings.visuals.logo.indexOf("firebasestorage") > -1
        ) {
          this.orgLogoUrl = this.orgData.settings.visuals.logo;
        } else {
          this.orgLogoUrl = `${environment.firebaseImgUrl}${environment.firebaseConfig.storageBucket}/o/photos%2Forganisations%2F${this.orgData.key}%2ForgLogo?alt=media`;
        }
      } else {
        this.orgLogoUrl = null;
      }

      // console.log('authDependencies: ', authDependencies);
      this.prepareVariationData(
        userAuth,
        individual,
        organization,
        authDependencies
      )
        .toPromise()
        .then(
          variationRes => {
            observer.next(variationRes);
            observer.complete();
          },
          variationErr => {
            observer.error(variationErr);
            observer.complete();
          }
        );
    });
  };

  private buildRendererObject = ({
    individual,
    organization,
    variationSvgDataObj
  }) => {
    // console.log('1404 : variationSvgDataObj: ', lodash.cloneDeep(variationSvgDataObj));
    const svgUrl =
    variationSvgDataObj.svgUrl != null
    ? variationSvgDataObj.svgUrl
    : `${environment.rendererBaseUrl}/V1.svg`;
    return from(this.commonService.loadAndConnectRenderer()).pipe(
      map(
        () => {
          let orientation = this.useFFOrientation || this.orientation;
          return {
            design: svgUrl,
            data: variationSvgDataObj,
            formfactor:
              this.rendererFormFactor &&
                orientation &&
                this.rendererFormFactor[orientation] &&
                (this.rendererAction == "Print" ||
                  this.useFFOrientation == this.orientation)
                ? {
                  target: {
                    fullBleed: {
                      width: parseFloat(
                        this.rendererFormFactor[orientation].fWidth
                      ),
                      height: parseFloat(
                        this.rendererFormFactor[orientation].fHeight
                      )
                    },
                    crop: {
                      width: parseFloat(
                        this.rendererFormFactor[orientation].cWidth
                      ),
                      height: parseFloat(
                        this.rendererFormFactor[orientation].cHeight
                      )
                    }
                  }
                }
                : {},
            context: {
              Action: this.rendererAction ? this.rendererAction : "Display",
              Visibility: this.rendererVisibility
                ? this.rendererVisibility
                : this.isForOverlay
                  ? "Overlay"
                  : this.presentationUsage
                    ? "Presentation"
                    : "Capture",
              UntaggedObjectVisibility: this.isForOverlay ? "hide" : null,
              RenderBleedArea: false,
              filename: `${individual.firstName}_${individual.lastName
                }_${new Date().getTime()}`,
              extension: "pdf"
            }
          };
        },
        err => {
          console.log("err: 1046", err);
        }
      )
    );
  };

  async setMessageLog(err: any) {
    if (!err) return;
    try {
      let userAuth = getQueryParams();
      let message = {
        timestamp: new Date().getTime(),
        details: `Fun: getSVGFromRenderer
          <br/>orgId: ${userAuth.orgID}
          <br/><br/>Error: renderer failed
          <br/><br/>ErrorDetails: ${this.commonService.prepareErrorMessage(
          err,
          null,
          true
        )}
          <br/><br/>URL: ${window.location.href}`,
        type: "error",
        area: MessageArea.SVG
      };
      await this.messagesService.setMessage(Role.SUPERADMIN, message);
    } catch (e) {
      console.log("e: setMessageLog: DigitalIDService", e);
    }
  }

  // --- get renderer assignments
  async getRendererTimestampFormat() {
    return await this.commonService.vipPassRenderer.getTimestampFormat();
  }

  private getSVGFromRenderer = rendererObject => {
    return new Observable(observer => {
      if (
        rendererObject &&
        rendererObject.data &&
        (rendererObject.data["ind.photoUrl"] == undefined ||
          rendererObject.data["ind.photoUrl"] == "")
      ) {
        rendererObject.data["ind.photoUrl"] = environment.placeholderImageUrl;
      }

      from(
        this.commonService.vipPassRenderer.getSVGFromRenderer(rendererObject)
      ).subscribe(
        (base64: any) => {
          let deviceInfo = getDeviceInfo();
          let browser = lodash.get(deviceInfo, `device.Browser.model`);
          let osVersion = lodash.get(deviceInfo, `device.OS.version`);
          if (lodash.toLower(browser) == "safari" && osVersion) {
            let majorVersion = lodash
              .chain(osVersion)
              .split(".")
              .first()
              .value();
            if (majorVersion <= 13) {
              base64 = base64.replace(
                /url\([^)#]*#/g,
                `url(${window.location}\#`
              );
            }
          }

          observer.next({ base64, designTypeID: this.designTypeId });
          observer.complete();
        },
        err => {
          console.log("err: ", err);
          observer.error(
            new CustomError(this.commonService.prepareErrorMessage(err))
          );
          observer.complete();
        }
      );
    });
  };

  formFactors: any;
  getSelectedFormFactorValue(
    userData
  ): Observable<{ bleedWidth; bleedHeight; cropWidth; cropHeight }> {
    return new Observable(observer => {
      for (let j = 0; j < this.formFactors.length; j++) {
        const ff = this.formFactors[j];
        if (
          userData &&
          userData.designs &&
          userData.designs.defaults &&
          userData.designs.defaults.formFactor === ff.key
        ) {
          this.convertFormFactorData(ff).subscribe(res => {
            observer.next(res);
            observer.complete();
          });
        }
      }
    });
  }

  convertFormFactorData(
    ff
  ): Observable<{ bleedWidth; bleedHeight; cropWidth; cropHeight }> {
    return new Observable(observer => {
      let bleedWidth: any;
      let bleedHeight: any;
      let cropWidth: any;
      let cropHeight: any;
      if (ff.Pixels == "cm") {
        cropHeight = ff.cHeight * 37.7952755906;
        cropWidth = ff.cWidth * 37.7952755906;
        bleedHeight = ff.fHeight * 37.7952755906;
        bleedWidth = ff.fWidth * 37.7952755906;
      } else if (ff.Pixels == "mm") {
        cropHeight = ff.cHeight * 3.7795275591;
        cropWidth = ff.cWidth * 3.7795275591;
        bleedHeight = ff.fHeight * 3.7795275591;
        bleedWidth = ff.fWidth * 3.7795275591;
      } else if (ff.Pixels == "pixel") {
        cropHeight = ff.cHeight;
        cropWidth = ff.cWidth;
        bleedHeight = ff.fHeight;
        bleedWidth = ff.fWidth;
      }
      observer.next({
        bleedWidth,
        bleedHeight,
        cropWidth,
        cropHeight
      });
      observer.complete();
    });
  }

  getCameraBoundingRec(svgWrapper: HTMLElement): Observable<DOMRect> {
    const objects = [];
    if (svgWrapper && svgWrapper.children[0]) {
      const { children: layers } = svgWrapper.children[0];

      for (let i = 0; i < layers.length; i++) {
        // if the layer tag is SVG, loop through its inner layers as well to find selfie camera element
        if (layers[i] && layers[i].tagName && layers[i].tagName == "svg") {
          // loop through inner tags of svg
          for (let k = 0; k < layers[i].children.length; k++) {
            const innerElement = layers[i].children[k];
            if (innerElement) {
              const jsonAttr = innerElement.getAttribute("v:json")
                ? innerElement.getAttribute("v:json")
                : innerElement.getAttribute("json");
              if (jsonAttr) {
                const parsedJsonAttr = JSON.parse(jsonAttr);
                if (
                  parsedJsonAttr.H5_ObjectID &&
                  parsedJsonAttr.H5_ObjectID === environment.ImageSelfieCamera
                ) {
                  objects.push(innerElement);
                }
              }
            }
          }
        } else {
          if (layers[i]) {
            const jsonAttr = layers[i].getAttribute("v:json")
              ? layers[i].getAttribute("v:json")
              : layers[i].getAttribute("json");
            if (jsonAttr) {
              const parsedJsonAttr = JSON.parse(jsonAttr);
              if (
                parsedJsonAttr.H5_ObjectID &&
                parsedJsonAttr.H5_ObjectID === environment.ImageSelfieCamera
              ) {
                objects.push(layers[i]);
              }
            }
          }
        }
      }
    }
    return new Observable(observer => {
      if (!objects || !objects.length) {
        return observer.error("no camera layer found!");
      }
      setTimeout(() => {
        let rect = objects[0].children[0].getBoundingClientRect();
        observer.next(rect);
        observer.complete();
      });
    });
  }

  getSvgPromise(
    orgData: any,
    indId: string,
    orgId: string,
    designTypeId: string,
    authDependencies?: CommonAuthDependencies
  ): Observable<any> {
    return new Observable(observer => {
      this.getSvg(
        orgData,
        indId,
        orgId,
        designTypeId,
        res => {
          // console.log('1662 : get svg : res: ', lodash.cloneDeep(res));
          if (res instanceof CustomError) {
            observer.error(res);
            // observer.next(`error, ${res}`)
          } else {
            observer.next(res);
          }
          observer.complete();
        },
        authDependencies
      );
    });
  }

  // --- check if org have valid license for given design type or not
  async doesOrgHaveValidLicenseForDesignType(orgId, designTypeId) {
    let promises = [];
    promises.push(this.licenseService.getOrgLicensesArr(orgId));
    promises.push(this.getDesignTypeIdInfo(designTypeId));
    let results = [];
    try {
      results = await Promise.all(promises);
    } catch (e) {
      return {
        res: false,
        error: new CustomError(
          "Error while org license check for design type",
          this.commonService.prepareErrorMessage(e)
        )
      };
    }

    let orgLicenses = results[0];
    let designTypeData = results[1];

    if (!designTypeData)
      return {
        res: false,
        error: new CustomError(
          "Design Type Data not found!",
          `designTypeId: ${designTypeId}`
        )
      };

    return {
      res: this.licenseService.isAnyLicenseValid(
        designTypeData.licenseType,
        orgLicenses
      )
    };
  }

  // --- create function for get svg file
  async getSvg(
    orgData: any,
    indId: string,
    orgId: string,
    designTypeId: string,
    callback: Function,
    authDependencies?: CommonAuthDependencies
  ) {
    let licenseCheckRes = await this.doesOrgHaveValidLicenseForDesignType(
      orgId,
      designTypeId
    );

    if (!licenseCheckRes.res) {
      callback(
        licenseCheckRes.error ||
        new CustomError(
          "Organization do not have a valid license to access the design type.",
          `designTypeId: ${designTypeId}`
        )
      );
      return;
    }

    // --- prepare user data
    let usersData = {};
    if (orgData && orgData.key == orgId) usersData[orgId] = orgData;

    let promises = [];

    // --- read individual data
    // console.log('1746 : /-*/-*/-/--- this.indData: ', lodash.cloneDeep(this.indData));
    // console.log('indId: ', indId);
    promises.push(
      // this.indData && this.indData._key == indId
      //   ? of(this.indData).toPromise() :
        orgId && indId ?
          await this.individualApi.getIndDataNew(
            orgId,
            indId,
            undefined,
            true,
            undefined,
            authDependencies?.indOperationsAuthDependencies
          )
        : of(null).toPromise()
    );

    // --- read variations of given design type
    // console.log('orgId: ', orgId);
    // console.log('designTypeId: ', designTypeId);
    // console.log('usersData: ', usersData);
    promises.push(
      this.variationsService.getVariationsOfDTByInheritance(
        Role.ORG,
        orgId,
        designTypeId,
        usersData
      )
    );

    // --- read variations order
    promises.push(
      this.variationsService.getVariationsOrderByInheritance(
        Role.ORG,
        orgId,
        usersData
      )
    );

    // --- resolve promises
    let [promisesRes, promisesErr] = await this.commonService.executePromise(
      Promise.all(promises)
    );

    // --- handle error
    if (promisesErr) {
      console.log("Error resolving promises", promises);
      callback(
        new CustomError(this.commonService.prepareErrorMessage(promises))
      );
      return;
    }

    // --- use promisesRes
    let indData = promisesRes[0];
    // console.log('1800 : /*-/-*/-/ : indData: ', lodash.cloneDeep(indData));
    let variationsObj = promisesRes[1];
    // console.log('1797 : variationsObj: ', lodash.cloneDeep(variationsObj));
    let variationOrders = promisesRes[2];

    if (!indData) {
      callback(
        new CustomError(
          "Individual Data Not Found!",
          `orgId: ${orgId}, indId: ${indId}`
        )
      );
      return;
    }

    // --- sort variations
    variationsObj = this.variationsService.sortDesignTypeVariationsByOrder(
      variationsObj,
      lodash.get(variationOrders, designTypeId)
    );
    // console.log('1815 : --/-/--- \n variationsObj: ', lodash.cloneDeep(variationsObj));
    // console.log('**/-*/-/*/-*/- \n designTypeId: ', designTypeId);

    this.fetchIndSvgData(
      orgData,
      indData,
      variationsObj,
      designTypeId,
      indSvgData => {
        // console.log('*-*---*--*-*-***** indSvgData: ', lodash.cloneDeep(indSvgData));
        callback(indSvgData);
      }
    );
  }

  fetchDesignTypeData(designTypeId: string, callback: Function) {
    let designsTypeDataSub = this.db
      .object(`/designs/DesignTypes/${designTypeId}`)
      .valueChanges()
      .subscribe(async (designsTypeData: any) => {
        designsTypeDataSub.unsubscribe();
        if (designsTypeData) {
          let designData: any = await this.getDesignByInheritance(
            designsTypeData.defaultDesign
          );
          if (designData) {
            let callbackObj = {};
            if (designData.hasOwnProperty("source")) {
              callbackObj["source"] = designData.source;
            }
            if (designData.hasOwnProperty("Vecta_io_ID")) {
              callbackObj["Vecta_io_ID"] = designData.Vecta_io_ID;
            }
            callbackObj["orientation"] = designData.orientation;
            callbackObj["designId"] = designsTypeData.defaultDesign;
            callbackObj["displayName"] = designsTypeData.name;
            callback(callbackObj);
          } else {
            callback(
              new CustomError(
                "default design id of design type wrong.",
                `designTypeId: ${designsTypeData.defaultDesign}`
              )
            );
          }
        } else {
          callback(
            new CustomError(
              "design type id wrong.",
              `designTypeId: ${designTypeId}`
            )
          );
        }
      });
  }

  fetchDesignDefaultFormFectorData(designTypeId: string, callback: Function) {
    let designsTypeDataSub = this.db
      .object(`/designs/DesignTypes/${designTypeId}`)
      .valueChanges()
      .subscribe((designsTypeData: any) => {
        designsTypeDataSub.unsubscribe();
        if (designsTypeData) {
          let designDataSub = this.db
            .object(`/designs/FormFactors/${designsTypeData.defaultFormFactor}`)
            .valueChanges()
            .subscribe((designData: any) => {
              designDataSub.unsubscribe();
              if (designData) {
                callback(designData);
              } else {
                callback(null);
              }
            });
        } else {
          callback(null);
        }
      });
  }

  getAdmissibleGrpList() {
    return new Promise(resolve => {
      try {
        this.setList("/admissibility-groups").then((res: any) => {
          resolve(res);
        });
      } catch (e) {
        resolve("error");
      }
    });
  }

  getAdmissibilGrp(id) {
    if (id != "") {
      return this.db.object(this.basePath + "/" + id).valueChanges();
    } else {
      return this.admissibilityGroup
        .snapshotChanges()
        .pipe(
          map(changes =>
            changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
          )
        );
    }
  }

  setBasePath(type) {
    if (type) {
      this.basePath = type;
      this.admissibilityGroup = this.db.list(type);
    }
  }

  admissibilGrpOrgDbPresent(id, tag) {
    return this.db
      .list("/organizations/" + id + this.basePath + "/")
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }

  private groupList = [];
  groupListDB = [];
  setList = async basePath => {
    this.setBasePath(basePath);
    return new Promise((resolve, reject) => {
      this.getAdmissibilGrp("").subscribe((res: any) => {
        this.groupList = [];
        this.groupList = res;
        this.getOrgList().then(value => {
          if (value) {
            this.groupListDB = [];
            //check design presence in org
            let sub = this.admissibilGrpOrgDbPresent(
              this.orgData.key,
              this.ListRole
            ).subscribe(async value => {
              sub.unsubscribe();

              if (value && value.length > 0) {
                this.groupListDB = value;
              }
              await this.filterTeammate("");
              resolve(this.groupList);
            });
          } else {
            resolve(this.groupList);
          }
        });
      });
    });
  };

  filterTeammate = async id => {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.groupListDB && this.groupListDB.length != 0) {
          this.groupListDB.map(async (key, index) => {
            if (key && key["Override"] === "hide") {
              this.groupList.splice(this.indexOf(key.key), 1);
            }
            if (key && key["Override"] === "edit") {
              if (this.groupList.find(o => o.key === key.key)) {
                let newObj = this.groupList.find(o => o.key === key.key);
                this.groupList[this.indexOf(key.key)] = this.groupListDB.find(
                  o => o.key === key.key
                );
                if (newObj.isAdminDesign) {
                  this.groupList[this.indexOf(key.key)].isAdminDesign =
                    newObj.isAdminDesign;
                }
              } else {
                this.groupList.push(key);
              }
            }
            if (key && key["Override"] === "add") {
              this.groupList.push(key);
            }
            if (index == this.groupListDB.length - 1) {
              resolve(true);
            }
          });
        } else {
          resolve(true);
        }
      } catch (e) {
        reject(false);
      }
    });
  };
  indexOf(e) {
    return this.groupList
      .map(val => {
        return val.key;
      })
      .indexOf(e);
  }

  admissibilGrpTeamDbPresent(id, tag) {
    return this.db
      .list("/studios/" + id + this.basePath + "/")
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }

  groupListTeammate = [];
  wholeOrgGroupList = [];
  ListRole = "admissibility-groups";
  getOrgList() {
    return new Promise(resolve => {
      let sub = this.admissibilGrpTeamDbPresent(
        this.orgData.studioID,
        this.ListRole
      ).subscribe(
        value => {
          sub.unsubscribe();
          this.groupListTeammate = [];
          if (value && value.length > 0) {
            this.groupListTeammate = value;
          } else {
            this.groupListTeammate = lodash.cloneDeep(this.groupList);
          }

          if (this.groupListTeammate && this.groupListTeammate.length != 0) {
            this.groupListTeammate.map((key, index) => {
              if (key && key["Override"] === "hide") {
                this.groupList.splice(this.indexOf(key.key), 1);
              }
              if (key && key["Override"] === "edit") {
                if (this.groupList.find(o => o.key === key.key)) {
                  this.groupList[
                    this.indexOf(key.key)
                  ] = this.groupListTeammate.find(o => o.key === key.key);
                } else {
                  this.groupList.push(key);
                }
              }
              if (key && key["Override"] === "add") {
                this.groupList.push(key);
              }

              if (index + 1 == this.groupListTeammate.length) {
                this.wholeOrgGroupList = lodash.cloneDeep(this.groupList);
                resolve(true);
              }
            });
          } else {
            resolve(false);
          }
        },
        error => {
          if (error) {
            this.wholeOrgGroupList = lodash.cloneDeep(this.groupList);
            resolve(true);
          }
        }
      );
    });
  }

  async fetchIndSvgData(
    orgData,
    indData,
    customDesignObj,
    designTypeId,
    callback: Function
  ) {
    // console.log('*-*-*-*-* \nindData: ', lodash.cloneDeep(indData));
    if (!customDesignObj.fields) customDesignObj.fields = [];
    if (!customDesignObj.variations) customDesignObj.variations = {};

    // fetch source
    let fields = customDesignObj.fields;
    let indValues = {};

    // --- for ind admissibillity group only
    if (
      fields.find(field => {
        return field == "ind_admissibilityGroup";
      })
    ) {
      let admissibleList: any = await this.getAdmissibleGrpList();
      let tempCustomDesignObjKeysArr = Object.keys(customDesignObj.variations);
      for (let i = 0; i < tempCustomDesignObjKeysArr.length; i++) {
        const tempCustomerDesignKey = tempCustomDesignObjKeysArr[i];
        if (
          customDesignObj.variations[tempCustomerDesignKey].conditions[
          "ind_admissibilityGroup"
          ] != "Any"
        ) {
          if (
            !admissibleList.find(admissibleGrp => {
              return (
                admissibleGrp.key ==
                customDesignObj.variations[tempCustomerDesignKey].conditions[
                "ind_admissibilityGroup"
                ]
              );
            })
          ) {
            customDesignObj.variations[tempCustomerDesignKey].conditions[
              "ind_admissibilityGroup"
            ] = "Any";
          }
        }
      }
    }

    fields.forEach(field => {
      let f = this.getFieldNameFromTag(field);
      if (f && (
        f.includes("accessCampus") 
     || f.includes("accessBus")
     || f.includes("accessCafeteria")
     || f.includes("accessSocial")
     || f.includes("accessAthletics")
     || f.includes("accessParking")
     || f.includes("permissionFlexSchedule")
     || f.includes("permissionToLeave"))) {
       let modeKey = f +'_mode';
       let startDateTimeKey = f +'_startDateTime';
       let endDateTimeKey = f +'_endDateTime';
       let validAfterDateTimeKey = f +'_validAfterDateTime';
       let invalidAfterDateTimeKey = f +'_invalidAfterDateTime';
         indValues[field] = this.commonService.isDateTimeRangValid(
           (indData[modeKey] != undefined && indData[modeKey] !== "") ? indData[modeKey] : 2,
           'now',
           indData[startDateTimeKey],
           indData[endDateTimeKey],
           indData[invalidAfterDateTimeKey],
           indData[validAfterDateTimeKey]);
     } else if (f == "ASBMember") {
        indValues[field] =
          indData[f] != undefined && indData[f] != "" ? indData[f] : false;
        // console.log(`indValues[${field}]: `, indValues[field]);
      } else {
        indValues[field] =
          indData[f] != undefined && indData[f] != "" ? indData[f] : "Any";
      }
    });
    // console.log('2164 : indValues: ', lodash.cloneDeep(indValues));
    let customDesignObjKeysArr = Object.keys(customDesignObj.variations);
    let indValuesCopy = lodash.cloneDeep(indValues);
    let matchFound = false;
    for (let i = 0; i < customDesignObjKeysArr.length; i++) {
      indValues = lodash.cloneDeep(indValuesCopy);
      const variationKey = customDesignObjKeysArr[i];
      let fieldsArr = Object.keys(indValues);
      for (let j = 0; j < fieldsArr.length; j++) {
        const fieldKey = fieldsArr[j];
        // console.log('fieldKey: ', fieldKey);
        if (
          customDesignObj.variations[variationKey].conditions.hasOwnProperty(
            fieldKey
          ) == undefined
        ) {
          // console.log('*-*-*-*--*-*-*- \nvariationKey: ', variationKey);
          // console.log('*-*-*-*--*-*-*- \nfieldKey: ', fieldKey);
          customDesignObj.variations[variationKey].conditions[fieldKey] = "Any";
        }
      }

      // ----
      // console.log('variationKey: ', variationKey);
      let variationConditionArr = Object.keys(
        customDesignObj.variations[variationKey].conditions
      );
      variationConditionArr.forEach(cond => {
        if (
          String(
            customDesignObj.variations[variationKey].conditions[cond]
          ).toLowerCase() == "any"
        ) {
          delete indValues[cond];
          delete customDesignObj.variations[variationKey].conditions[cond];
        }
      });

      if (customDesignObj.variations[variationKey].override == "hide") {
        continue;
      }

      //convert string bolean values to original boolean to make comparision work
      Object.keys(indValues).forEach(key => {
        if (typeof indValues[key] == "string") {
          let indValue = indValues[key].toLowerCase();
          indValues[key] =
            indValue == "true"
              ? true
              : indValue == "false"
                ? false
                : indValue == ""
                  ? "any"
                  : indValues[key].toLowerCase();
        }
        if (
          typeof customDesignObj.variations[variationKey].conditions[key] ==
          "string"
        ) {
          let conditionValue = customDesignObj.variations[
            variationKey
          ].conditions[key].toLowerCase();
          if (key == "ind_accessCampus" || key == "ind_accessBus"
          || key == "ind_accessCafeteria"
          || key == "ind_accessSocial"
          || key == "ind_accessAthletics"
          || key == "ind_accessParking"
          || key == "ind_permissionFlexSchedule"
          || key == "ind_permissionToLeave"
          || key == "ind_ASBMember") {
            customDesignObj.variations[variationKey].conditions[key] =
              conditionValue == "yes" || conditionValue == "true"
                ? true
                : conditionValue == "no" ||
                  conditionValue == "any" ||
                  conditionValue == "false"
                  ? false
                  : customDesignObj.variations[variationKey].conditions[
                    key
                  ].toLowerCase();
          } else {
            customDesignObj.variations[variationKey].conditions[key] =
              conditionValue == "true"
                ? true
                : conditionValue == "false"
                  ? false
                  : conditionValue == ""
                    ? "any"
                    : customDesignObj.variations[variationKey].conditions[
                      key
                    ].toLowerCase();
          }
        }
      });

      if (
        _.isEqual(
          customDesignObj.variations[variationKey].conditions,
          indValues
        )
      ) {
        if (
          (customDesignObj.variations[variationKey].results.hasOwnProperty(
            "orgType"
          ) &&
            customDesignObj.variations[variationKey].results.orgType ==
            "any") ||
          (customDesignObj.variations[variationKey].results.hasOwnProperty(
            "orgType"
          ) &&
            customDesignObj.variations[variationKey].results.orgType != "any" &&
            orgData.type ==
            customDesignObj.variations[variationKey].results.orgType) ||
          !customDesignObj.variations[variationKey].results.hasOwnProperty(
            "orgType"
          )
        ) {
          let res: any = await this.getDesignByInheritance(
            customDesignObj.variations[variationKey].results["designId"]
          );
          if (res != null) {
            let callbackObj = {};
            if (res.hasOwnProperty("source")) {
              callbackObj["source"] = res.source;
            }
            if (res.hasOwnProperty("Vecta_io_ID")) {
              callbackObj["Vecta_io_ID"] = res.Vecta_io_ID;
            }
            // console.log('*-*-*-*-*-* \n customDesignObj.variations[variationKey]: ', lodash.cloneDeep(customDesignObj.variations[variationKey]));
            if (
              customDesignObj.variations[variationKey].results.hasOwnProperty(
                "backgroundId"
              )
            ) {
              callbackObj["backgroundId"] =
                customDesignObj.variations[variationKey].results[
                "backgroundId"
                ];
            }
            callbackObj["orientation"] =
              customDesignObj.variations[variationKey].orientation ||
              res.orientation;
            callbackObj["bgOverhang"] =
              customDesignObj.variations[variationKey].results["bgOverhang"];
            callbackObj["designId"] =
              customDesignObj.variations[variationKey].results["designId"];
            callbackObj["displayName"] =
              customDesignObj.variations[variationKey].results["displayName"];
            callbackObj["variationId"] = variationKey;
            // console.log('*-*-*-*-*-* \n callbackObj: ', lodash.cloneDeep(callbackObj));
            callback(callbackObj);
          } else {
            continue;
          }
          matchFound = true;
          break;
        } else {
          continue;
        }
      }
    }

    // console.log('matchFound: ', matchFound);
    if (!matchFound) {
      this.fetchDesignTypeData(designTypeId, designTypeData => {
        // console.log('/-*/-*/-**-/-*/-*/-*/- designTypeData: ', lodash.cloneDeep(designTypeData));
        callback(designTypeData);
      });
    }
  }

  // --- unsubscribe method
  unSub(x: Subscription) {
    if (x && !x.closed) {
      x.unsubscribe();
    }
  }

  async getDesignByInheritance(designID) {
    const userAuth = getQueryParams();

    // --- prepare usersdata
    let usersData = {};
    if (this.orgData && this.orgData.key == userAuth.orgID) {
      usersData[userAuth.orgID] = this.orgData;
    }

    let designData = await this.designService.getDesignByInheritance(
      designID,
      Role.ORG,
      userAuth.orgID,
      usersData
    );
    if (!designData) return null;

    return { ...designData, key: designID };
  }

  getFieldNameFromTag(tag) {
    switch (tag) {
      case "ind_grade":
        return "class";
      default:
        return tag.split("_")[1];
    }
  }

  // --- get course list
  getCoursesList(orgID: string) {
    return this.db.list(`/courses/${orgID}`);
  }
}

export enum RendererMode {
  CAPTURE = "capture",
  PREVIEW = "preview"
}
