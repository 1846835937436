<!-- error part -->
<div *ngIf="errorMsg" style="overflow: hidden;">
  <!-- back button -->
  <div class="row h-100 w-10" style="margin: 20px; flex-wrap: nowrap;">
    <button nz-button nzType="primary" 
      class="col back-button d-flex-center" style="padding: 5px 20px;" (click)="onBack()">
      <i nz-icon nzType="left"></i>
      Back
    </button>
  </div>
  <div class="error-div row d-flex-center" [ngClass]="{'error-div-back': (isInfo)}">
    <p class="msg col-12" [ngClass]="{'error-div-back-msg': (isInfo)}" [innerHTML]="errorMsg"></p>
    <div class="col-12" style="text-align: center;" *ngIf="showErrBtns && errorMsg != 'You are not allow to view ID without your photo.'">
      <button nz-button nzType="primary" style="min-width: 150px;" (click)="onErrActionBtnClicked('takeSelfie')">Yes</button>
    </div>
    <div class="col-12" style="text-align: center; margin-top: 20px" *ngIf="showErrBtns && errorMsg != 'You are not allow to view ID without your photo.'">
      <button nz-button nzType="primary" style="min-width: 150px;"
        (click)="onErrActionBtnClicked('denyTakingSelfie')">No</button>
    </div>
    <div class="col-12" style="text-align: center; margin-top: 20px" *ngIf="errorMsg == 'You are not allow to view ID without your photo.'">
      <button nz-button nzType="primary" style="min-width: 150px;" (click)="onErrActionBtnClicked('goBack')">OK</button>
    </div>
  </div>
</div>
    
<!-- digital id part -->
<div class="no-select" *ngIf="!errorMsg">
  <div class="top-section" [style.backgroundColor]="!loadingSvg && patchesToDisplay.length > 0 ? '#414a4c' : '#ffffff'">
    <div class="row h-100 w-100 d-flex-center" style="margin: unset; flex-wrap: nowrap;">
      <button nz-button nzType="primary" 
        class="col back-button d-flex-center" (click)="onBack()">
        <i nz-icon nzType="left"></i>
        Back
      </button>

      <button nz-button nzType="primary" *ngIf="qrCodeData && qrCodeData.hash"
        class="col show-qr-code-button d-flex-center ml-8"
        (click)="showQrCode()">
        <i nz-icon nzType="qrcode" nzTheme="outline"></i>
      </button>

      <div class="col p0 ml-8 h-100" style="overflow-x: auto; overflow-y: hidden;">
        <div class="row h-100 d-flex patch-list-container">
          <div class="col patch-container d-flex-center" *ngFor="let patch of patchesToDisplay"
          [style.backgroundColor]="patch.color">
            <p class="m0" [style.color]="patch.textColor">{{ patch.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div #canvasTarget (click)="showQrCode()" id="canvasTarget"></div>
  
  <!-- this div is to keep full screen height when svg still didn't started loading (useful in flipper to show white background) -->
  <div *ngIf="loadingSvg == undefined" style="height: 100vh;"></div>

  <div class="align centered" *ngIf="loadingSvg">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      <span>Loading...</span>
  </div>
</div>
    
    