import { Injectable } from "@angular/core";
import { debounceTime, map, take } from "rxjs/operators";
import { AngularFireDatabase } from "@angular/fire/database";
import { HttpClient } from "@angular/common/http";
import { IndividualFull } from "./individual-full.class";
import { concat, Observable } from "rxjs";
import lodash from "lodash";
import { CommonServiceService } from "../common/common-service.service";
import { environment } from "src/environments/environment";
import { GetIndPhotoUsingPrefSettings } from "../pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import { CacheKeys, CacheService } from "../cache/cache.service";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ElasticEndPoint, IndSearchType, Module } from "../enums/general.enums";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";
import { AuthDependency } from "../interface/types";
import { IndStatusAtOrgRulesService } from "../ind-status-at-org-rules/ind-status-at-org-rules.service";
import { Role } from "../enums/user.enums";
import { PreferencesService } from "../preferences/preferences.service";
import { MediaService } from "../media/media.service";
const collection = "individuals";
@Injectable({
  providedIn: "root"
})
export class IndividualApiService {
  constructor(
    private db: AngularFireDatabase,
    private http: HttpClient,
    private commonService: CommonServiceService,
    public getIndPhotoUsingPrefSettings: GetIndPhotoUsingPrefSettings,
    private cacheService: CacheService,
    private apiHelperService: ApiHelperService,
    private preferencesService: PreferencesService,
    private mediaService: MediaService
  ) {}

  // --- convert ind data to full individual object
  getFullInd(individual: any, orgType?) {
    let indID = lodash.get(individual, "key") || lodash.get(individual, "_key");

    let fullInd = new IndividualFull();
    fullInd._new = false;
    fullInd.copyInto(individual, indID, orgType);
    return fullInd;
  }

  // --- get individual data
  async getIndData(
    orgID: string,
    indID: string,
    propsToReturn: string[] = ["all"],
    full: boolean = false,
    useCache?: boolean,
    authDependencies?: AuthDependency[]
  ) {
    if (!orgID || !indID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${orgID}/${indID}/`;
    cachePath += this.commonService.getCachePathForProps(propsToReturn);

    let indData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.IndData, cachePath);
      if (cachedData) indData = cachedData.value;
    }

    // --- make http request if cache data isn't available
    if (!indData) {
      let reqBody = { orgID, indID, propsToReturn, authDependencies };

      let response: any = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getIndividualData,
        reqBody
      );
      response = lodash.get(response, "result");
      if (!response || response.success != 1)
        throw new Error(lodash.get(response, "message"));
      indData = lodash.get(response, "data");

      // --- set cache
      this.cacheService.set(CacheKeys.IndData, cachePath, indData);
    }

    if (full) indData = this.getFullInd(indData);

    return indData;
  }
  
  async getIndDataNew(
    orgID: string,
    indID: string,
    propsToReturn: string[] = ["all"],
    full: boolean = false,
    useCache?: boolean,
    authDependencies?: AuthDependency[]
  ) {
    if (!orgID || !indID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${orgID}/${indID}/`;
    cachePath += this.commonService.getCachePathForProps(propsToReturn);

    let indData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.IndData, cachePath);
      if (cachedData) indData = cachedData.value;
    }

    // --- make http request if cache data isn't available
    if (!indData) {
      let reqBody = { orgID, indID, propsToReturn, authDependencies };

      let response: any = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getIndividualData,
        reqBody
      );
      response = lodash.get(response, "result");
      if (!response || response.success != 1)
        throw new Error(lodash.get(response, "message"));
      indData = lodash.get(response, "data");

      // --- set cache
      this.cacheService.set(CacheKeys.IndData, cachePath, indData);
    }

    if (full) indData = this.getFullInd(indData);

    return indData;
  }

  // --- search individuals
  async searchInds(
    orgID: string,
    indSearchType: IndSearchType,
    userInput: string,
    propsToReturn: string[] = ["key"],
    role?: string,
    restrictions?: any,
    placeOfSearch: string = ""
  ) {
    if (!orgID || !indSearchType || !userInput)
      throw new Error("Params missing!");

    let payload: any = {
      orgID: orgID,
      type: indSearchType,
      userInput: userInput,
      propsToReturn: propsToReturn,
      placeOfSearch: placeOfSearch
    };
    if (role) payload.role = role;
    if (restrictions) payload.restrictions = restrictions;

    let [matchingIndsRes, matchingIndsErr] = await this.commonService.executePromise(this.apiHelperService.postToCloudFn(
      CloudFnNames.searchInds,
      payload
    ));
    if (matchingIndsErr) {
      throw matchingIndsErr;
    }
    return lodash.get(matchingIndsRes, "result.data");
  }

  // --- get individual statusAtOrg
  getIndStatusAtOrg(indData, orgType) {
    if (!indData || !orgType) throw new Error("Params missing in getIndStatusAtOrg call!");
    if(indData.statusAtOrg) return indData.statusAtOrg;
    return IndStatusAtOrgRulesService.getDefaultValueForStatusAtOrg(orgType)
  }

  getWellFormattedPhone(num: string) {
    num = `${num}`; // --- convert to string
    if (num.includes("+")) {
      return num.toString().replace(/[- )(]/g, "");
    } else {
      let number = num.toString().replace(/\D/g, "");
      // --- add international prefix
      if (number.length > 10) {
        number = "+" + number;
      } else if (number.length < 10) {
        number = "+1" + number;
      }
      return number;
    }
  }

  getCountryCode(number: string) {
    const phoneNumber = parsePhoneNumberFromString(number, "US");
    if (phoneNumber && phoneNumber.country) {
      return { countryCode: phoneNumber.country, number: phoneNumber.number };
    } else {
      if (phoneNumber && phoneNumber.number) {
        return { countryCode: "US", number: phoneNumber.number };
      } else {
        return { countryCode: "US", number: number };
      }
    }
  }

  /**
   * @deprecated can't use this method now as db permissions don't allow to read all inds of org
   */
  getAll(orgID: string) {
    return new Promise((resolve, reject) => {
      try {
        this.getAllInds(orgID).subscribe(res => {
          if (res.length > 0) {
            let filterData = res.filter(item => !item.disabled);
            resolve(filterData);
          } else {
            resolve(res);
          }
        });
      } catch (e) {
        console.log("error---", e);
      }
    });
  }

  /**
   * @deprecated can't use this method now as db permissions don't allow to read all inds of org
   */
  getAllInds(orgID, indIds: string[] = []) {
    return this.db
      .list("/individuals/" + orgID)
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }


  // * @deprecated can't use this method now as db permissions don't allow to read all inds of org
  /**
   * @param orgID Current organization ID
   * @param indIds Provide the array of indId
  */
  async getSelectedIndsFromDB(orgID, indIds: string[] = []) {
    const promises = indIds.map(key =>
      this.db.object(`/individuals/${orgID}/${key}`).query.once('value')
    );
    return Promise.all(promises).then(snapshots => {
      return snapshots.map(snapshot => ({key: snapshot.key, ...snapshot.val()}));
    })
  }

  // --- get individual by hash
  async getIndByHash(
    orgID: string,
    hash: string,
    propsToReturn: string[] = ["all"],
    full: boolean = false
  ) {
    if (!orgID || !hash || !propsToReturn) throw new Error("Params missing!");

    let matchingInds = await this.searchInds(
      orgID,
      IndSearchType.HASH,
      hash,
      propsToReturn
    );
    let matchinInd = lodash.first(matchingInds);

    if (full && matchinInd) matchinInd = this.getFullInd(matchinInd);

    return matchinInd;
  } 

  // updated individual photo based on pref settings
  setIndividualsPhoto(
    orgID,
    indID,
    individual,
    updatedObj,
    currentCycle,
    authDependencies?: AuthDependency[]
  ) {
    let temp = lodash.cloneDeep(updatedObj);
    if (!individual.photos) {
      updatedObj.photos = {
        [currentCycle]: {
          photoUrl: temp.photoUrl,
          photoStatus: null,
          photoUpdated: Date.now()
        }
      };
    } else {
      updatedObj.photos = {
        ...individual.photos,
        [currentCycle]: {
          photoUrl: temp.photoUrl,
          photoStatus: null,
          photoUpdated: Date.now()
        }
      };
    }
    if (!individual.photoYear) updatedObj.photoYear = currentCycle;
    updatedObj.photoUrl = null;
    updatedObj.photo = null;
    updatedObj.photoStatus = null;
    return this.updateInd(orgID, indID, updatedObj, authDependencies);
  }

  replaceSpecialChar(str) {
    if (str) {
      str = str.replace(/[\u{0080}-\u{FFFF}]/gu, "");
    }
    return str;
  }

  // ---- observe individual data changes
  observeIndData(orgID: string, indID: string, minGapBwEvents?: number) {
    let dbObservable = this.db
      .object(`/individuals/${orgID}/${indID}`)
      .valueChanges();

    return concat(
      dbObservable.pipe(take(1)),
      dbObservable.pipe(debounceTime(minGapBwEvents || 1000))
    );
  }

  createHash(firstName: string, lastName: string, orgID: string) {
    if (!firstName) firstName = "";
    if (!lastName) lastName = "";

    function getHashFromString(str) {
      var hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash >>> 0;
    }

    let str: any =
      firstName
        .replace(/ /g, "")
        .trim()
        .toUpperCase() +
      "_" +
      lastName
        .replace(/ /g, "")
        .trim()
        .toUpperCase();

    str = this.replaceSpecialChar(str);
    if (str.length > 50) {
      str = str.substring(0, 50);
    }

    const toHash = str + "_" + orgID + "_" + new Date().getTime();
    return str + "_" + getHashFromString(toHash);
  }

  // --- move s3 image from one location to other
  async moveS3Image(fromPath, toPath, preventOriginalImgDeletion = false) {
    let reqBody = {
      type: "moveS3File",
      fromPath,
      toPath,
      preventOriginalImgDeletion
    };
    let [response, err] = await this.commonService.executePromise(
      this.http
        .post(`${environment.awsImageUpload.endPoint}/s3`, reqBody, {
          headers: { "x-api-key": environment.awsImageUpload.xApiKey }
        })
        .pipe(take(1))
        .toPromise()
    );
    if (err) {
      console.log(
        `Error while moving s3 file from path: ${fromPath} to path ${toPath}`
      );
      return false;
    }
    return true;
  }

  /**
   * bulk update individuals node of particular organization
   * @param orgID organization id
   * @param updateObj update object of individuals
   * @deprecated can't use this method now as db permissions don't allow to update all inds of org at once
   */
  async bulkUpdateIndividuals(orgID, updateObj) {
    if (!orgID || !updateObj) throw Error("Params missing!");

    await this.db.object(`individuals/${orgID}`).update(updateObj);
  }

  /**
   * Calculate similarity of strings
   * @param {string} s1 string 1
   * @param {string} s2 string 2
   * @returns float number representing similarity of s1 & s2 between 0 & 1
   */
  similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (
      (longerLength - this.editDistance(longer, shorter)) /
      parseFloat(longerLength)
    );
  }

  /**
   * Calculate edit distance between 2 strings (Levenshtein distance).
   * @param {string} s1 string 1
   * @param {string} s2 string 2
   * @returns maximum numbers of changes(insertions, deletions or substitutions) required to convert one string to another
   */
  editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  // TODO::update to new logic of comparision hash
  // --- check if inds are dups or not
  isIndDup(individual1: IndividualFull, individual2: IndividualFull) {
    // --- special cases
    // - "unnamed guardian"
    const isSpecialCase = (individual: IndividualFull) => {
      if (!individual) return false;

      if (
        individual.firstName &&
        individual.firstName.toLowerCase() == "unnamed" &&
        individual.lastName &&
        individual.lastName.toLowerCase() == "guardian"
      ) {
        return true;
      }
      return false;
    };

    // --- check for special cases for unmatched
    if (isSpecialCase(individual1) || isSpecialCase(individual2)) return false;

    // --- check for special conditions
    // 1. if non-matching middle name present, its not a match
    // 2. if non-matching suffix present, its not a match
    if (
      (individual1.middleName || individual2.middleName) &&
      individual1.middleName != individual2.middleName
    )
      return false;
    if (
      (individual1.suffix || individual2.suffix) &&
      individual1.suffix != individual2.suffix
    )
      return false;

    // --- calculate Levenshtein distance for different important fields of individual
    let fnDistance =
      individual1.firstName && individual2.firstName
        ? this.similarity(individual1.firstName, individual2.firstName)
        : -1;
    let lnDistance =
      individual1.lastName && individual2.lastName
        ? this.similarity(individual1.lastName, individual2.lastName)
        : -1;
    let studentIDDistance =
      individual1.studentID && individual2.studentID
        ? this.similarity(individual1.studentID, individual2.studentID)
        : -1;

    // --- define weights of different field distances
    let studentIDDistanceWeight = 6;
    let lnDistanceWeight = 2;
    let fnDistanceWeight = 1;

    // --- calculate weighted average distance for all the above distances
    let weightSum =
      (studentIDDistance != -1 ? studentIDDistanceWeight : 0) +
      (lnDistance != -1 ? lnDistanceWeight : 0) +
      (fnDistance != -1 ? 1 : 0);
    let weightedSum =
      (studentIDDistance != -1
        ? studentIDDistance * studentIDDistanceWeight
        : 0) +
      (lnDistance != -1 ? lnDistance * lnDistanceWeight : 0) +
      (fnDistance != -1 ? fnDistance * fnDistanceWeight : 0);
    let weightedAverageDistance = weightedSum / weightSum;

    // --- if weighted average distance is >= 86%, consider them as a same individuals, otherwise not
    return weightedAverageDistance >= 0.86;
  }

  // --- get individual email (to use in authentication)
  async getIndEmail(orgID: string, indID: string, password: string, isAutoLogin: boolean = false) {
    if (!orgID || !indID || !password) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, indID, password, isAutoLogin };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getIndEmail,
      reqBody
    );
    return lodash.get(response, "result.data.email");
  }

  // --- reset individual password
  async resetIndPW(orgID: string, indID: string) {
    let resetIndPW = await this.apiHelperService.postToCloudFn(
      CloudFnNames.resetIndPW,
      {
        orgID,
        indID
      }
    );
    return lodash.get(resetIndPW, "result.data");
  }

  // --- add individual
  async addInd(
    orgID: string,
    individual: any,
    indID?: string,
    authDependencies?: AuthDependency[],
    customAuthToken?: string
  ) {
    let addIndRes = await this.apiHelperService.postToCloudFn(
      CloudFnNames.addInd,
      {
        orgID,
        individual,
        indID,
        authDependencies
      },
      customAuthToken
    );
    return lodash.get(addIndRes, "result.data");
  }

  // --- set individual password
  async setIndPW(orgID: string, indID: string, newPW: string, token?: string) {
    // --- check password validation
    if (!this.commonService.isPwValid(newPW))
      throw new Error("Invalid password!");

      let setIndPWRes = await this.apiHelperService.postToCloudFn(
        CloudFnNames.setIndPW,
        {
          orgID,
          indID,
          newPW,
          token
        }
      );
      return lodash.get(setIndPWRes, "result.data");
  }

  // --- update individual password
  async updateInd(
    orgID: string,
    indID: string,
    individualObj: any,
    authDependencies?: AuthDependency[],
    customAuthToken?: string
  ) {
    let setIndPWRes = await this.apiHelperService.postToCloudFn(
      CloudFnNames.updateInd,
      {
        orgID,
        indID,
        individualObj,
        authDependencies
      },
      customAuthToken
    );
    return lodash.get(setIndPWRes, "result.data");
  }

  // --- check if ind status is active or not
  isIndActive(indData: any) {
    if (!indData) return false;
    return this.commonService.isDateTimeRangValid(
      indData.idValid_mode,
      'now',
      indData.idValid_startDateTime,
      indData.idValid_endDateTime,
      indData.idValid_invalidAfterDateTime,
      indData.idValid_validAfterDateTime
    )
  }

  // --- helper method to get scanned individual
  async getScannedInd(
    scanType: "BARCODE" | "QR",
    orgID: string,
    scannedData: any
  ) {
    let matchingIndKey;
    let matchingIndData;

    // --- search by student ID
    const searchByStudentID = async (studentID: string) => {
      // --- search by student ID
      let matchingInds = await this.searchInds(
        orgID,
        IndSearchType.STUDENT_ID,
        studentID,
        ["key"]
      );
      let matchingInd = lodash.nth(matchingInds, 0);
      return lodash.get(matchingInd, "key");
    };

    // --- search by student ID
    const searchByHash = async (hash: string) => {
      // --- search by student ID
      let matchingInds = await this.searchInds(
        orgID,
        IndSearchType.HASH,
        hash,
        ["key"]
      );
      let matchingInd = lodash.nth(matchingInds, 0);
      return lodash.get(matchingInd, "key");
    };

    // --- search individual based on scanned data
    if (scanType == "BARCODE") {
      // --- search by student ID
      matchingIndKey = await searchByStudentID(scannedData);

      // --- search by ind hash
      if (!matchingIndKey) matchingIndKey = await searchByHash(scannedData);
    } else if (scanType == "QR") {
      // --- search by ind hash
      let indHash = lodash.get(scannedData, "indhash");
      if (indHash) matchingIndKey = await searchByHash(indHash);

      // --- search by student ID
      let studentID = lodash.get(scannedData, "indhash");
      if (!matchingIndKey && studentID)
        matchingIndKey = await searchByStudentID(studentID);
    }

    // --- read matching ind data
    if (matchingIndKey)
      matchingIndData = await this.getIndData(
        orgID,
        matchingIndKey,
        undefined,
        true
      );

    return matchingIndData;
  }

  // --- get total ind count
  async getTotalIndCount(orgId: string): Promise<any> {
    let body = { hashCode: ElasticEndPoint.TOTAL_IND_COUNT, orgID: orgId };
    let res: any = await this.http
      .post(
        `${environment.newApiBaseUrl}${environment.elasticSearch.endPoint}`,
        this.commonService.prepareElasticReqBodyNew(body),
        { responseType: "text" }
      )
      .toPromise();
    let elasticResponse = this.commonService.decryptResponse(res);

    if (elasticResponse.success && elasticResponse.response) {
      return elasticResponse.response.count || 0;
    } else {
      throw new Error("Individual data not found");
    }
  }

  // --- This method is to fix individual data where hash is not present, it creates the hash and sets to individual's data
  async generateAndSetIndHash(orgID: string, indID: string, indData?: any) {
    if (!orgID || !indID) throw new Error("Params missing!");

    if (!indData || !indData.firstName || !indData.lastName)
      indData = await this.getIndData(orgID, indID, ["firstName", "lastName"]);

    let hash = this.createHash(indData.firstName, indData.lastName, orgID);

    await this.updateInd(orgID, indID, { hash });

    return hash;
  }

  /**
  * To get ind photo
  * @orgId organization ID
  * @indData ind data object
  */
   async getIndPhoto(orgId: string, indData: any) {
    let readRequiredDataPromises = [];
    readRequiredDataPromises.push(
      this.commonService.getOrgCurrentCycle(orgId)
    );
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        orgId,
        "photoUsedInID",
        undefined,
        true,
        "value"
      )
    );
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        orgId,
        "PhotoDefBG",
        null,
        true,
        "value"
      )
    );
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        orgId,
        "DIYCapturePolicy",
        null,
        true,
        "value"
      )
    );
    let readRequiredDataPromisesRes = await Promise.all(
      readRequiredDataPromises
    );
    let currentCycle = readRequiredDataPromisesRes[0];
    let photoUsedInIDPrefValue = readRequiredDataPromisesRes[1];
    let mediaID = readRequiredDataPromisesRes[2];
    let diyCapturePolicyPredValue = readRequiredDataPromisesRes[3];
    let photoDefBGPref;
    if (mediaID == "none") photoDefBGPref = mediaID;
    else if (mediaID) {
      let mediaData = await this.mediaService.getMediaByInheritance(
        Role.ORG,
        orgId,
        mediaID
      );
      photoDefBGPref = lodash.get(mediaData, "source.url");
    }
    let photoData: any = await this.getIndPhotoUsingPrefSettings.transform(
      indData,
      photoUsedInIDPrefValue,
      currentCycle,
      "",
      orgId,
      null,
      photoDefBGPref,
      true,
      true,
      "sanitizedObjectURL"
    );
    indData.photoURL = photoData.photoUrl;
    return indData;
  };
}
