import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import lodash from "lodash";
import moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";
import { Role } from "../enums/user.enums";
import { CommonFirebaseServices } from "../firebase.service";
import {
  ReplacementTags
} from "../inheritance/inheritance.service";
import { PreferencesService } from "../preferences/preferences.service";
import { getQueryParams } from "../utils/queryParams.utils";
import { Organization } from "./organization.class";
@Injectable({
  providedIn: "root"
})
export class OrganizationApiService {
  usersData = {};
  
  constructor(
    private db: AngularFireDatabase,
    private firebaseServices: CommonFirebaseServices,
    private preferencesService: PreferencesService,
    public http: HttpClient,
    private apiHelperService: ApiHelperService
  ) {
    let queryParams = getQueryParams();
    let orgID = queryParams.orgID;
  }

  async getDIYCapPolicyPrefValue(orgId: string): Promise<String> {
    return await this.preferencesService.getPreferenceByInheritance(
      Role.ORG,
      orgId,
      "DIYCapturePolicy",
      this.usersData,
      true,
      "value"
    );
  }


  getOrgLogo = (organization: Organization) => {
    const logo = organization.settings.visuals.logo;
    // if full logo URL exist pass as value
    if (
      logo &&
      (logo.indexOf("http") > -1 || logo.indexOf("firebasestorage") > -1)
    ) {
      return new Observable<string>(observable => {
        observable.next(logo);
      });
    } else if (logo) {
      return this.firebaseServices
        .getDownloadURL([logo])
        .pipe(map(mappedUrls => mappedUrls[logo]));
    }
    return new Observable<string>(observable => {
      observable.next("");
    });
  };

  // --- get org today event list
  async getOrgTodayEventList(orgId: string, cycle: string) {
    let startTime: any = moment()
      .startOf("day")
      .format("x");
    let endTime: any = moment()
      .endOf("day")
      .format("x");
    let snapshot = await this.db
      .list(`/event/organizations/${orgId}/${cycle}/`)
      .query.orderByChild("startDate")
      .startAt(startTime)
      .endAt(endTime)
      .once("value");

    let eventsList = snapshot.val();
    eventsList = lodash.map(eventsList, (eventData, key) => {
      return { ...eventData, key };
    });
    return eventsList;
  }

  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };
  readonly dbPath = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/landing-page/landingPages`;

  // ---
  async createOrg(reqBody: any) {
    let response: any = await this.apiHelperService.postToCloudFn(
      CloudFnNames.publicOrgSignup,
      reqBody
    );
    response = lodash.get(response, "result");
    if (!response || response.success != 1)
      throw new Error(lodash.get(response, "message"));
    return lodash.get(response, "data");
  }
}
