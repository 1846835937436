import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { CommonServiceService } from "../common/common-service.service";
import { AuthApiService } from "./auth.api.service";
import lodash from "lodash";
import { RelationsService } from "../relations.service";
@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private authApi: AuthApiService,
    private commonService: CommonServiceService,
    private router: Router,
    private relationsService: RelationsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable(observer => {
      // --- check if user can enter into photo routes
      const canActivatePhotoRoutes = async () => {
        // --- check if its photo route
        let destinationPath = lodash.replace(state.url, /\?.*/g, "");
        if (!this.isPhotoPath(destinationPath)) return false;

        // --- check params
        let queryParams = route.queryParams;
        let orgID = queryParams.orgID;
        let indID = queryParams.indID || queryParams.indKey;
        if (!orgID || !indID) return false;

        // --- check if current ind is guardian
        let isGuardian = await this.authApi.isUserGuardian();
        if (!isGuardian) return false;

        // --- check if current ind is guardian of given child
        let claims = await this.authApi.getUserCustomClaim();
        let claimsIID = lodash.get(claims, "IID");
        let relations = await this.relationsService.getAllRelations(
          orgID,
          claimsIID
        );
        return this.relationsService.isGuardianOf(claimsIID, indID, relations);
      };

      const canActivatePage = async () => {
        // --- wait for auth state to be ready
        let authorized = await this.commonService.waitForAuthorization(
          this.authApi
        );
        if (!authorized) return false;

        // --- if currently logged in user is not anonymous, allow entering page
        let queryParams = route.queryParams;
        let isGivenUserLoggedIn = await this.authApi.isGivenUserLoggedIn(
          queryParams.orgID,
          queryParams.scannedUserKey || queryParams.visitorIndID || queryParams.indID || queryParams.indKey
        );
        if (isGivenUserLoggedIn) return true;

        // --- special checks for photo routes (to allow guardian to take photo of child)
        return canActivatePhotoRoutes();
      };

      canActivatePage()
        .then(canEnter => {
          // --- if user can not enter into page, redirect user to the right page
          if (!canEnter) {
            let destinationPath = lodash.replace(state.url, /\?.*/g, "");
            let queryParams = lodash.cloneDeep(route.queryParams); // route queryparams are not mutable so cloning to mutable obj
            this.redirectToCorrectPage(queryParams, destinationPath);
          }

          observer.next(canEnter);
          observer.complete();
        })
        .catch(err => {
          observer.error(err);
          observer.complete();
        });
    });
  }

  // --- check if path is photo capture path
  async isPhotoPath(path: string) {
    return lodash.some(
      CommonServiceService.photoPathArr,
      route => route == path
    );
  }

  // redirect user to correct page (eg. login page)
  async redirectToCorrectPage(
    queryParams: any,
    destinationPath: string
  ): Promise<boolean> {
    let currentPath = location.pathname;
    let orgID = queryParams.orgID;
    let indID = queryParams.indID || queryParams.indKey;

    // --- redirect TVT users to TVT home page
    if (
      lodash.some(
        CommonServiceService.tvtPathArr,
        route => route == currentPath
      )
    ) {
      await this.router.navigate(["/visitor"], {
        queryParams: { orgID: orgID }
      });
      return true;
    }

    // --- redirect regular users to login page
    if (orgID && indID) {
      // --- add additional query params
      if (destinationPath == "/photo" || destinationPath == `stage/photo`)
        queryParams.redir = "photo";

      await this.router.navigate(["auth"], { queryParams });
      return true;
    }

    // --- redirect user to find ind page
    if (orgID) {
      await this.router.navigate(["generic"], { queryParams: { orgID } });
      return true;
    }

    // --- redirect users to find/choose organization page
    await this.router.navigate(["organization"], { queryParams });
    return true;
  }
}
